/* DO NOT EDIT. THIS FILE IS AUTOGENERATED. RUN `yarn build:i18n` instead. */

export const locale = {
    "alphabet": "abcdefghijklmnopqrstuvwxyzåäö",
    "article": {
        "sources-title": "Källor",
        "references-title": "Referenser",
        "authors-title": "Sakkunniga",
        "former-authors": "Tidigare sakkunniga",
        "international-cooperation-title": "Internationellt samarbete",
        "international-cooperation-content": "Medibas har ett samarbete med redaktionerna i Norsk Elektronisk Legehåndbok (NEL) och Deximed. Deras texter är viktiga källor i Medibas revisionsarbete"
    },
    "audio-fallback": "Din webbläsare stödjer inte audiouppspelning ",
    "back": "Tillbaka",
    "block-content": {
        "unknown-error": "Det har uppstått ett fel. Ta gärna kontakt med oss. Information: \n{{blockContent}}"
    },
    "breadcrumb": {
        "breadcrumbs": "Brödsmulor",
        "pathnames": {
            "/calculators": "Kalkylatorer",
            "/concurrent-logins": "Samtidig inloggning",
            "/cookie-declaration": "Om cookies",
            "/glossary": "Ordlista",
            "/logged-out": "Utloggad",
            "/search": "Sök",
            "/travel-vaccinations": "Vaccinationer",
            "/important-updates": "Viktiga uppdateringar"
        }
    },
    "choose-a-country": "Välj ett land",
    "click-here": "Klicka här",
    "close-gallery": "Stäng bildgallerian",
    "close-snackbar": "Stäng",
    "close-popover": "Stäng",
    "content-menu": "Meny",
    "copy": {
        "false": "Kopiera länken",
        "true": "Länken kopierad"
    },
    "course": {
        "title": "Kurs"
    },
    "error-page": {
        "status-codes": {
            "400": "Bad request",
            "404": "Sidan hittades inte",
            "405": "Method not allowed",
            "500": "Internal server error",
            "200": "Ett oväntat fel har inträffat"
        },
        "unexpected-error": "Ett oväntat fel har inträffat"
    },
    "example": "Exempel",
    "external-link": {
        "checkbox-label": "Visa inte detta meddelande igen",
        "close": "Stäng",
        "title": "Extern länk"
    },
    "fallback-template-name": "Artikel",
    "favorite": {
        "favorites": "Favoriter",
        "latest": "Senaste {{count}}",
        "add-to-favorites": "Lägg till i favoriter",
        "remove-from-favorites": "Ta bort från favoriter",
        "manage-all": "Se/redigera alla",
        "none": "Inga favoriter ännu",
        "sort": {
            "abc": "Alfabetisk",
            "options": "Sortera favoriter",
            "time": "Datum",
            "template": "Kategorier"
        },
        "sort-by": "Sortera efter:"
    },
    "feedback": {
        "body": "Meddelande",
        "cancellation": "Uppsägning av abonnemang",
        "editorial-feedback": "Generell återkoppling/tips till redaktionen",
        "email": "E-post",
        "error-sending-ticket": "Det uppstod ett fel när du skickade. Var god kontakta oss på info@medibas.se",
        "forgotten-password": "Glömt användarnamn/lösenord",
        "general": "Återkoppling om den här sidan",
        "groupId": "Vad gäller ditt ärende?",
        "invoice": "Fakturafrågor",
        "name": "Namn",
        "order": "Beställning",
        "price-quote": "Prisförfrågan",
        "send": "Skicka",
        "subject": "Ämne",
        "ticket-sent": "Ärendet har skickats",
        "title": "Support och feedback"
    },
    "general-error": "Oj, ett fel uppstod.",
    "glossary": {
        "header": "Ordlista",
        "helper": "Skriv in minst två bokstäver eller tryck på en bokstav",
        "search-label": "Sök i ordlistan",
        "title": "Ordlista (A–Ö)"
    },
    "go-to-homepage": "Tillbaka till förstasidan ",
    "header": {
        "feedback": "Skicka synpunkter",
        "logo-alt": "Medibas",
        "notifications": "Meddelanden",
        "profile": "Visa din profil",
        "show-notifications": "Visa {{amount}} meddelanden"
    },
    "home": "Hem",
    "important-updates": {
        "important-update": "Viktig uppdatering",
        "important-updates": "Viktiga uppdateringar"
    },
    "last-approved-by": "Sakkunnig",
    "last-revised": "Senast reviderad",
    "last-updated": "Senast uppdaterad",
    "revision-interval": "Revisionsintervall",
    "layouts": {
        "article-skeleton": "Sidan laddas",
        "error": "Fel",
        "unauthorized": "Obehörig"
    },
    "loading-please-wait": "Sidan laddas, vänligen vänta",
    "login": "Logga in",
    "login-again": "Logga in igen",
    "logout": "Logga ut",
    "megamenu": {
        "no-topics-found": "Inga underkapitel hittades",
        "close": "Stäng huvudmenyn",
        "open": "Öppna huvudmenyn"
    },
    "missing-description": "Saknar beskrivning",
    "new-abbreviation": "NY",
    "new-articles": {
        "new-and-updated-articles": "Nya och uppdaterade artiklar",
        "copy-as-html": {
            "false": "Kopiera som HTML",
            "true": "Kopierade",
            "tooltip": "kopiera hela artikeln som HTML-kod"
        },
        "professional": "Professionella artiklar",
        "public": "Öppna artiklar",
        "search-in-articles": "Sök i artiklar",
        "from-date": "Från datum",
        "type-of-articles": "Typ av artiklar"
    },
    "next": "Nästa",
    "no-country": "Inget land hittades",
    "notifications": {
        "no-new-notifications": "Inga nya meddelanden",
        "notification-was-removed": "Meddelandet borttaget",
        "remove-notification": "Ta bort meddelandet",
        "title": "Meddelanden"
    },
    "open-section": {
        "false": "Öppna sektionen",
        "true": "Stäng sektionen"
    },
    "outdated": {
        "bg-alt": "Loggor på modernare webbläsare",
        "description": "Av säkerhetsskäl ber vid dig att uppdatera webbläsaren",
        "link": "Se alternativen här",
        "title": "Denna webbläsare är föråldrad"
    },
    "paywall": {
        "close": "Stäng",
        "login": {
            "not-logged-in": "Har du redan ett abonnemang? Logga in här",
            "no-subscription": "Logga in med ett annat konto"
        },
        "order": "Beställ abonnemang",
        "title": "Vill du fortsätta läsa?"
    },
    "print": "Skriv ut",
    "profile": {
        "logged-in-as-organisation": "Du är inloggad med organisationsanvändare:",
        "choose-region": "Välj region",
        "close": "Stäng profil",
        "color-mode": {
            "auto": "Byt till ljust läge",
            "dark": "Byt till automatisk inställning",
            "light": "Byt till mörkt läge ",
            "period": {
                "auto": "Mörkt läge mellan kl {{from}} och kl {{to}}",
                "dark": "Bytt till mörkt läge",
                "light": "Bytt till ljust läge"
            }
        },
        "contact": "Kontakta oss",
        "dictionary": {
            "toggle-mark-words": "Aktivera ordförklaringar från ordlistan",
            "description": "När funktionen är aktiverad kan du markera ord i texten och få ordförklaring från ordlistan"
        },
        "edit": "Gå till din profil",
        "favorites": "Dina favoriter",
        "font-size": "Textstorlek",
        "logged-out": {
            "login-button": "Logga in på Medibas",
            "login-text": ["Du är inte inloggad", ""],
            "login-title": "",
            "request-licenses": "Begära licenser",
            "send-request": "Skicka förfrågan",
            "sending": "Skickar...",
            "sending-failed": "Sending av epost har feilet",
            "example-email": "exempel@exempel.se",
            "type-email": "Skriv in epostadress",
            "invalid-email": "Ogiltig e-postadresse",
            "subscribe-button": "Beställ abonnemang",
            "subscribe-text": "Har du inget abonnemang på Medibas?",
            "request-subject": "Förfrågningar om licenser",
            "requester-text": "Följande e-postadress begär fler licenser i NEL:"
        },
        "notifications": "Meddelanden",
        "title": "Profil",
        "verified": "Verifierad användare"
    },
    "published": "Publicerad",
    "qr": {
        "body": "",
        "header": "Dela patientinformationen"
    },
    "realtime-guideline": {
        "footer-tooltip": "Ej aktuell"
    },
    "regional-content": {
        "default": "(standard)",
        "no-content-for-region": "Den här artikeln har inget regionalt innehåll för {{region}}. Var god välj en annan region.",
        "no-region": "Ingen region",
        "no-region-selected": "Ingen region vald. Vänligen välj en region för att se regionalt innehåll.",
        "set-as-default": "Spara som standard"
    },
    "release-notes": "Versionsdetaljer",
    "reviewed": "Reviderad de senaste 90 dagarna",
    "reviewed-and-new": "Reviderade och nya artiklar",
    "revised-abbreviation": "REV",
    "saved": "Sparat",
    "search": {
        "all-hits": "Sorterat sökresultat",
        "arrow-directions": {
            "arrow-up": "UPP- ",
            "down": "NERPIL ",
            "press": "Tryck ",
            "and": "och ",
            "to-navigate": "för att navigera i listan"
        },
        "clear-search": "Töm sökfältet",
        "default-tab-label": "Alla träffar",
        "error": "Søket mislyktes. Vennligst start et nytt søk. Hvis problemet oppstår, ta kontakt med oss.",
        "filter-title": "Avgränsa sökresultatet",
        "found-x-hits-for-query": "Sökresultatet för '{{query}}'{{type}}",
        "history": "Sökhistorik",
        "improve-search": "Sökord",
        "layout-title": "Sök",
        "new": "Ny",
        "no-hits": "Din sökning gav tyvärr inga resultat.",
        "no-hits-tips": [
            "Söktips:",
            "Kontrollera din stavning",
            "Prova andra ord som betyder samma sak"
        ],
        "no-hits-for-term": "Ingen träffar för sökordet '{{query}}'",
        "revised": "Rev",
        "search": "Sök",
        "searching": "Söker...",
        "sending-failed": "Sending av epost har feilet",
        "searching-please-wait": "Söker, var god vänta...",
        "show-more-hits": "Visa fler träffar",
        "too-long": "Visar sökresultat för '{{query}}', eftersom sökordet är begränsat till att innehålla maximalt 10 ord.",
        "404-search": "Försök söka efter artikel",
        "fuzzy": "<strong>Inga artiklar matchade din sökning efter '{{query}}'.</strong><br />Sökresultatet nedan är baserat på en sökning på ord som liknar det du skrivit."
    },
    "share-article": "Dela informationen",
    "show-more": "Visa mer",
    "show-navigation": "Visa navigationen",
    "show-reference": "Visa referens",
    "show-references": "Visa referenser",
    "show-tools": "Visa verktyg",
    "site-name": "Medibas – Kunskapsstöd för hälso- och sjukvårdspersonal",
    "skip-to-main-content": "Gå direkt till huvudinnehållet",
    "tools": {
        "calculators": {
            "action": "Se alla",
            "title": "Kalkylatorer och formulär"
        },
        "checklists": {
            "action": "",
            "title": "Checklistor"
        },
        "courses": {
            "action": "Se alla kurser",
            "title": "Kurs"
        },
        "show-more": "Se fler",
        "show-less": "Se färre",
        "loading": {
            "action": "",
            "content": "Vänligen vänta",
            "title": "Laddas"
        },
        "patient-information": {
            "action": "Se alla",
            "title": "Patientinformation"
        },
        "regional-content": {
            "action": "Se innehåll",
            "content": "Den här artikeln innehåller en beskrivning av regionala rutiner",
            "title": "Regionalt innehåll"
        },
        "related-articles": {
            "action": "",
            "title": "Relaterade artiklar"
        },
        "title": "Verktyg"
    },
    "unauthenticated-request": "Du måste vara inloggad",
    "unauthorized-request": "Obehörig åtgärd",
    "profession-popup": {
        "message": "Du har inte angett något yrke/inriktning. Välj nedan.",
        "profession-input-label": "Yrke",
        "speciality-input-label": "Specialitet",
        "submit-button": "Uppdatera"
    },
    "video": {
        "print-placeholder": "Online-versionen av den här artikeln innehåller en film. Använd QR-koden eller webbadressen ovan för att gå till online-versionen som innehåller filmen."
    },
    "meta": {
        "og-title-suffix": ""
    }
} as const